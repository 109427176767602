








































import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
	components: {
		BaseButton: () => import('./BaseButton.vue')
	}
})
export default class BaseDialog extends Vue {
	@Prop({ default: '' })
	title: string;

	@Prop({ default: '' })
	description: string;

	@Prop({ default: true })
	displayCloseButton: boolean;

	@Prop({ default: true })
	displayButtonsSection: boolean;
}
